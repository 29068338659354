<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">
   <defs>
   </defs>
   <g id="Clubhouse" transform="translate(-210 -528)">
    <rect :fill="background" data-name="Rectangle 75" height="60" id="Rectangle_75" transform="translate(210 528)" width="60"/>
    <g id="Clubhouse_PrimaryLogo_Blurple" transform="translate(219.297 542.137)">
     <g data-name="Group 111" id="Group_111" transform="translate(0 25.75)">
      <g data-name="Group 110" id="Group_110" transform="translate(0 0)">
       <g data-name="Group 109" id="Group_109">
        <g data-name="Group 108" id="Group_108">
         <g data-name="Group 107" id="Group_107">
          <path d="M95.823,271.929l-.752.645a2.195,2.195,0,1,0,.027,2.862l.752.645a3.2,3.2,0,0,1-2.459,1.129,3.289,3.289,0,0,1-2.338-.9,3.243,3.243,0,0,1,0-4.595,3.244,3.244,0,0,1,2.338-.914A3.212,3.212,0,0,1,95.823,271.929Z" data-name="Path 251" id="Path_251" style="fill: #6515dd;" transform="translate(-90.1 -270.8)"/>
          <path d="M136.6,277.994V271.8h1.061v6.194Z" data-name="Path 252" id="Path_252" style="fill: #6515dd;" transform="translate(-130.353 -271.666)"/>
          <path d="M150.8,284.5h1.075v2.62a.981.981,0,1,0,1.962,0v-2.606h1.075v2.674a1.805,1.805,0,0,1-.591,1.438,2.249,2.249,0,0,1-1.478.484,2.187,2.187,0,0,1-1.464-.484,1.763,1.763,0,0,1-.578-1.411Z" data-name="Path 253" id="Path_253" style="fill: #6515dd;" transform="translate(-142.645 -282.659)"/>
          <path d="M187.7,277.994V271.8h1.061v2.365a1.438,1.438,0,0,1,1.438-.752,2.1,2.1,0,0,1,1.585.672,2.474,2.474,0,0,1,0,3.332,2.121,2.121,0,0,1-1.585.672,1.43,1.43,0,0,1-1.438-.752v.645H187.7Zm3.292-1.263a1.467,1.467,0,0,0,0-1.961,1.277,1.277,0,0,0-.94-.39,1.393,1.393,0,0,0-.981.39,1.429,1.429,0,0,0,0,1.961,1.37,1.37,0,0,0,.981.39A1.235,1.235,0,0,0,190.992,276.731Z" data-name="Path 254" id="Path_254" style="fill: #6515dd;" transform="translate(-174.587 -271.666)"/>
          <path d="M227.9,277.994V271.8h1.061v2.338a1.389,1.389,0,0,1,1.3-.712,1.586,1.586,0,0,1,1.639,1.8v2.768h-1.061v-2.62c0-.618-.322-.994-.9-.994a.967.967,0,0,0-.981,1.034v2.58Z" data-name="Path 255" id="Path_255" style="fill: #6515dd;" transform="translate(-209.386 -271.666)"/>
          <path d="M262.2,285.878a2.272,2.272,0,0,1,.712-1.693,2.534,2.534,0,0,1,3.466,0,2.368,2.368,0,0,1,0,3.386,2.534,2.534,0,0,1-3.466,0A2.272,2.272,0,0,1,262.2,285.878Zm3.439,1.008a1.389,1.389,0,0,0,.4-1.008,1.337,1.337,0,0,0-.4-.994,1.279,1.279,0,0,0-.981-.4,1.337,1.337,0,0,0-.994.4,1.379,1.379,0,0,0-.39.994,1.434,1.434,0,0,0,.39,1.008,1.411,1.411,0,0,0,1.975,0Z" data-name="Path 256" id="Path_256" style="fill: #6515dd;" transform="translate(-239.078 -281.794)"/>
          <path d="M303.1,284.5h1.075v2.62a.981.981,0,1,0,1.962,0v-2.606h1.075v2.674a1.805,1.805,0,0,1-.591,1.438,2.249,2.249,0,0,1-1.478.484,2.187,2.187,0,0,1-1.464-.484,1.763,1.763,0,0,1-.578-1.411Z" data-name="Path 257" id="Path_257" style="fill: #6515dd;" transform="translate(-274.483 -282.659)"/>
          <path d="M337.472,286.838a1.385,1.385,0,0,0,1.182.564c.564,0,.806-.215.806-.484a.4.4,0,0,0-.255-.349,2.427,2.427,0,0,0-.417-.175,5.359,5.359,0,0,1-.551-.188c-.725-.255-1.1-.618-1.1-1.263a1.169,1.169,0,0,1,.457-.981,1.775,1.775,0,0,1,1.129-.363,1.741,1.741,0,0,1,1.451.658l-.591.631a1.031,1.031,0,0,0-.873-.376c-.322,0-.537.161-.537.4a.355.355,0,0,0,.2.309,3.184,3.184,0,0,0,.363.161c.134.04.376.121.564.188.833.3,1.2.7,1.2,1.33,0,.833-.618,1.424-1.841,1.424a1.969,1.969,0,0,1-1.854-.914Z" data-name="Path 258" id="Path_258" style="fill: #6515dd;" transform="translate(-303.655 -281.88)"/>
          <path d="M366.6,285.965a2.362,2.362,0,0,1,.658-1.72,2.332,2.332,0,0,1,1.693-.645,2.086,2.086,0,0,1,1.585.645,2.22,2.22,0,0,1,.618,1.612c0,.134-.013.255-.013.376h-3.48a1.229,1.229,0,0,0,1.357,1.169,1.7,1.7,0,0,0,1.209-.511l.645.618a2.48,2.48,0,0,1-1.881.806,2.422,2.422,0,0,1-1.72-.631,2.246,2.246,0,0,1-.658-1.679v-.04Zm3.48-.484a.892.892,0,0,0-.336-.685,1.14,1.14,0,0,0-.793-.3,1.377,1.377,0,0,0-.86.282.973.973,0,0,0-.4.7Z" data-name="Path 259" id="Path_259" style="fill: #6515dd;" transform="translate(-329.452 -281.88)"/>
         </g>
        </g>
       </g>
      </g>
     </g>
     <g data-name="Group 113" id="Group_113" transform="translate(10.575)">
      <g data-name="Group 112" id="Group_112" transform="translate(0 16.426)">
       <path d="M408.765,307.3a1.865,1.865,0,1,0,0,3.73,1.886,1.886,0,0,0,1.865-1.865A1.824,1.824,0,0,0,408.765,307.3Z" data-name="Path 260" id="Path_260" style="fill: #6515dd;" transform="translate(-406.9 -307.3)"/>
      </g>
      <path d="M432.706,257.1l-11.845,3.763v-3.73L408.1,261.19v10.831l10.994-3.5v3.73l14.463-4.614-3.926-3.828Zm-13.579,9.587-9.227,2.945v-7.1l9.227-2.945Zm11.191.131-9.424,3.01v-7.1l8.7-2.781-2,4.188Z" data-name="Path 261" id="Path_261" style="fill: #6515dd;" transform="translate(-407.707 -257.1)"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>